import { Widget } from '@typeform/embed-react'
import * as queryString from 'query-string'
import React from 'react'
import SyncLoader from 'react-spinners/SyncLoader'

const MainPage = props => {
  const { formId, params: RawParams } = queryString.parse(props.location.search)

  let params = ''
  if (typeof window !== `undefined`) {
    //  @ts-ignore
    params = JSON.parse(window.atob(RawParams))
  }

  const onSubmit = () => {
    if (typeof window !== `undefined`) {
      window.location.replace('https://unocart.com')
    }
  }
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          height: '100vh',
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <SyncLoader size={20} />
        <h1 style={{ marginTop: 50 }}>Loading...</h1>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
          height: '100vh',
          width: '100vh'
        }}
      >
        <Widget
          hidden={params}
          id={formId}
          style={{ width: '100vw', height: '100vh' }}
          className="form"
          onSubmit={onSubmit}
        />
      </div>
    </>
  )
}

export default MainPage
